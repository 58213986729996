import React, { Component } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { enquireScreen } from "enquire-js";

import Header from "./Home/Nav0"
import Footer from "./Home/Footer2"
import Home from "./Home"
import Prosjekter from "./Prosjekter"
import About from "./Om oss"
import ProjectPage from "./ProjectPage"
import Team from "./Team"
import Energy from "./Energy";

import { Nav00DataSource, Footer20DataSource } from "./Home/data.source.js";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    return (
      <Router>
        <div>
          <div className="content">
            <Header
              id="Nav0_0"
              key="Nav0_0"
              dataSource={Nav00DataSource}
              isMobile={this.state.isMobile}
            />,
            <Route exact path="/" component={Home} />
            <Route exact path="/prosjekter" component={Prosjekter} />
            <Route exact path="/energy" component={Energy} />
            <Route exact path="/aboutus" component={About} />
            <Route exact path={`/prosjekter/:internal_title`} component={ProjectPage} />
            <Route exact path="/team" component={Team} />
          </div>
          <Footer
            id="Footer2_0"
            key="Footer2_0"
            dataSource={Footer20DataSource}
            isMobile={this.state.isMobile}
          />
        </div>
      </Router>
    );
  }
}

export default App;
