import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col, Radio, Input, Button, Form, InputNumber } from 'antd';
import { CheckSquareOutlined, CloseCircleOutlined, DownCircleOutlined, QuestionOutlined } from '@ant-design/icons';
import locale from "../Home/_locale"
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import "./less/energyCalculator.less"

function renderResultCard({ powerPriceQ1Q2, expectedPowerPriceQ4, turnoverQ1Q2, procentageOfTurnover, powerCostQ1Q2, expectedPowerCostQ4, savings25p, savings45p }, scrollDown) {
  console.log({ powerCostQ1Q2 })


  return (

    <div className="resultCard">
      <div className="resultCard__content">
        <div className="resultCard__content__item">
          <h3 className="resultCard__content__item__title">Strømutgifter av omsetning</h3>
          <p className="resultCard__content__item__value" style={{ fontWeight: "bold", textDecoration: "underline", color: procentageOfTurnover > 3 ? "green" : "red", fontSize: "20pt", paddingTop: "20px" }}>{procentageOfTurnover.toFixed(2)} %</p>

          <h4 className="resultCard__content__item__section_title" style={{ paddingTop: "10px" }}>Beregningsgrunnlag Q1-Q2</h4>
          <h3 className="resultCard__content__item__title">Total strøm kostnad</h3>
          <p className="resultCard__content__item__value">{Math.round(powerCostQ1Q2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} NOK</p>
          <h3 className="resultCard__content__item__title">Strømpris</h3>
          <p className="resultCard__content__item__value">{Math.round(powerPriceQ1Q2)} øre/kWt</p>
          <h3 className="resultCard__content__item__title">Omsetning</h3>
          <p className="resultCard__content__item__value">{Math.round(turnoverQ1Q2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} MNOK</p>


          {expectedPowerCostQ4 ? <div>
            <h4 className="resultCard__content__item__section_title" style={{ paddingTop: "10px" }}>Beregningsgrunnlag Q4</h4>
            <h3 className="resultCard__content__item__title">Forventet total strøm kostnad</h3>
            <p className="resultCard__content__item__value">{Math.round(expectedPowerCostQ4).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} NOK</p>
            <h3 className="resultCard__content__item__title">Forventet strømpris</h3>
            <p className="resultCard__content__item__value">{Math.round(expectedPowerPriceQ4)} øre/kWt</p>


            <h4 className="resultCard__content__item__section_title" style={{ paddingTop: "10px" }}>Besparing Q4</h4>
            <h3 className="resultCard__content__item__title">Forventet besparing 25%</h3>
            <p className="resultCard__content__item__value" style={{ fontWeight: "bold", textDecoration: "underline", color: "green" }}>
              {Math.round(savings25p).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} kr
            </p>
            <h3 className="resultCard__content__item__title">Forventet besparing 45%</h3>
            <p className="resultCard__content__item__value" style={{ fontWeight: "bold", textDecoration: "underline", color: "green" }}>
              {Math.round(savings45p).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} kr
            </p></div> : ""}
          <DownCircleOutlined onClick={scrollDown} className="bounce" style={{ color: "lightgray", fontSize: "80pt", padding: "50px" }} />
        </div>


      </div></div>
  )
}



function Feature1(props) {
  const ref = React.useRef(null);
  const { ...tagProps } = props;
  const { isMobile, scrollDown } = tagProps;
  console.log({ scrollDown })
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = React.useState('optional');
  const [calcType, setCalcType] = React.useState("estimate");
  const [haveEditedQ4, setHaveEditedQ4] = React.useState(false);
  const [calcResult, setCalcResult] = React.useState({
    "estimatedPowerPriceQ1Q2": 200,
    "expectedPowerPriceQ4": 400,
    "powerUsageQ1Q2": 0,
    "turnoverQ1Q2": 10,
    "procentageOfTurnover": 0,
    "qualified": null,
    "estimatedSavings25p": 0,
    "estimatedSavings45p": 0,
    "powerCostQ1Q2": 0
  });

  // const scrollDown = () => {
  //   scrollRef.current.scrollIntoView({behavior: 'smooth', block: "center"});
  // };

  const calculateResults = (values) => {
    console.log({ values })
    const { turnoverQ1Q2, powerUsageQ1Q2, powerPriceQ1Q2, powerCostQ1Q2, expectedPowerPriceQ4, expectedPowerUsageQ4 } = values;

    const turnoverQ1Q2InNOK = turnoverQ1Q2 * 1000000;

    // Calculate if qualified
    const priceQ1Q2InNOK = powerPriceQ1Q2 / 100;
    const _powerCostQ1Q2 = powerCostQ1Q2 ? powerCostQ1Q2 : powerUsageQ1Q2 * priceQ1Q2InNOK

    const procentageOfTurnover = (_powerCostQ1Q2 / turnoverQ1Q2InNOK) * 100;

    const qualified = procentageOfTurnover > 3.0;

    console.log({ qualified, procentageOfTurnover, _powerCostQ1Q2 })

    // Calculate savings
    const priceQ4InNOK = expectedPowerPriceQ4 / 100;
    const expectedPowerCostQ4 = expectedPowerUsageQ4 * priceQ4InNOK;

    let savings25p = ((priceQ4InNOK - 0.7) * expectedPowerUsageQ4) * 0.25;
    let savings45p = ((priceQ4InNOK - 0.7) * expectedPowerUsageQ4) * 0.45;
    if (savings25p > 3500000) savings25p = 3500000;
    if (savings45p > 3500000) savings45p = 3500000;

    // const savoings25p = powerCostQ1Q2 - powerCostQ4;

    // const procentageOfTurnover = Math.round((powerUsageQ1Q2 * estimatedPowerPriceQ1Q2 / 100) / turnover * 100);
    // const estimatedSavings25p = Math.round(powerUsageQ1Q2 * estimatedPowerPriceQ1Q2 * 0.25);
    // const estimatedSavings45p = Math.round(powerUsageQ1Q2 * estimatedPowerPriceQ1Q2 * 0.45);
    // const qualified = procentageOfTurnover > 0.5;


    setCalcResult({
      qualified,
      procentageOfTurnover,
      powerCostQ1Q2: _powerCostQ1Q2,
      turnoverQ1Q2,
      powerPriceQ1Q2,
      expectedPowerPriceQ4,
      expectedPowerUsageQ4,
      expectedPowerCostQ4,
      savings25p,
      savings45p
    })
  }

  const dataSource = {
    wrapper: { className: "home-page-wrapper" },
    OverPack: { className: "home-page feature1", playScale: 0.3 },
    imgWrapper: { className: "feature1-img", md: 12, xs: 24 },

    textWrapper: { className: "feature1-text", md: 12, xs: 24 },
    title: { className: "feature1-title", children: locale("about_header_1") },
    content: {
      className: "feature1-content",
      children:
        locale("about_text_1"),
    },
  }

  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
        scaleY: '+=0.3',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      }
      : {
        x: '-=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
  };
  const tempClacQ1Q2 = () => {
    const { powerUsageQ1Q2, powerPriceQ1Q2 } = form.getFieldsValue();
    if (!powerUsageQ1Q2) return;
    if (powerUsageQ1Q2 <= 0) return;
    if (!powerPriceQ1Q2) return;
    if (powerPriceQ1Q2 <= 0) return;

    const powerCostQ1Q2 = powerUsageQ1Q2 * powerPriceQ1Q2 / 100;
    console.log({ powerCostQ1Q2 })
    form.setFieldValue("powerCostQ1Q2", powerCostQ1Q2);
    if (!haveEditedQ4)
      form.setFieldValue("expectedPowerUsageQ4", powerUsageQ1Q2 / 2);

  }

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const formItemLayout = {
    labelCol: { span: 14 },
    wrapperCol: { span: 14 },
  }

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 0 },
  }

  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>

        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <h2 key="h1" {...dataSource.title}>
            Kan din bedrift få strømstøtte?
          </h2>
          <div key="p">
            <Form name="basic"
              form={form}
              layout="vertical"
              // initialValues={{ remember: true, powerPriceQ1Q2: 200, expectedPowerPriceQ4: 400, turnoverQ1Q2: 10, powerUsageQ1Q2: 1000000 }}
              initialValues={{ remember: true, powerPriceQ1Q2: 200, expectedPowerPriceQ4: 400 }}
              onFinish={calculateResults}
              onValuesChange={onRequiredTypeChange}
              requiredMark={requiredMark}
              {...formItemLayout}>
              <Form.Item name="turnoverQ1Q2" label={locale("energy_calc_turnover")} rules={[{ required: true, message: locale("energy_calc_turnover") }]} >
                <InputNumber min={0.01} addonAfter={locale("energy_calc_turnover_suffix")} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item name="estOfCost" {...formItemLayout}>
                <Radio.Group defaultValue={calcType} buttonStyle="solid" onChange={(value) => setCalcType(value.target.value)}>
                  <Radio.Button value="estimate">Forbruk</Radio.Button>
                  <Radio.Button value="cost">Kostnad</Radio.Button>
                </Radio.Group>
              </Form.Item>
              {calcType === "estimate" ?
                <div>
                  <h3>Forbruk Q1 og Q2</h3>
                  <Form.Item onChange={tempClacQ1Q2} name="powerUsageQ1Q2" label={locale("energy_calc_power_usage_Q1Q2")} rules={[{ required: calcType === "estimate", message: locale("energy_calc_power_usage_Q1Q2") }]}>
                    <InputNumber min={0} formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} addonAfter={locale("energy_calc_power_usage_Q1Q2_suffix")} style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item onChange={tempClacQ1Q2} name="powerPriceQ1Q2" label={locale("energy_calc_power_price_Q1Q2")} rules={[{ required: calcType === "estimate", message: locale("energy_calc_power_price_Q1Q2") }]}>
                    <InputNumber min={0} type="number" addonAfter={locale("energy_calc_power_price_Q1Q2_suffix")} style={{ width: "100%" }} />
                  </Form.Item>



                </div> :
                <Form.Item name="powerCostQ1Q2" label={locale("energy_calc_total_cost")} rules={[{ required: calcType === "cost", message: locale("energy_calc_total_cost") }]}>
                  <InputNumber min={0} formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} addonAfter={locale("energy_calc_total_cost_suffix")} style={{ width: "100%" }} />
                </Form.Item>
              }
              <h3>Estimert forbruk Q4</h3>
              <Form.Item name="expectedPowerUsageQ4" onChange={() => setHaveEditedQ4(true)} label={locale("energy_calc_power_usage_Q4")} rules={[{ required: false, message: locale("energy_calc_power_usage_Q4") }]}>
                <InputNumber min={0} formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} addonAfter={locale("energy_calc_power_usage_Q4_suffix")} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item name="expectedPowerPriceQ4" label={locale("energy_calc_power_price_Q4")} rules={[{ required: false, message: locale("energy_calc_power_price_Q4") }]}>
                <InputNumber min={0} type="number" addonAfter={locale("energy_calc_power_price_Q4_suffix")} style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item {...buttonItemLayout}>
                <Button type="primary" htmlType="submit">Beregn</Button>
              </Form.Item>

            </Form>
          </div>

        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          {
            calcResult.qualified === null ? <QuestionOutlined style={{ color: "gray", fontSize: "100pt", padding: "20px", paddingTop: "200px" }} /> :
              calcResult.qualified === true ?
                <div>
                  <CheckSquareOutlined style={{ color: "Green", fontSize: "60pt", padding: "20px" }} /><br />
                  <p>Du kan mest sannsynlig få strømstøtte</p>
                  {renderResultCard(calcResult, scrollDown)}</div>
                : <div> <CloseCircleOutlined style={{ color: "Red", fontSize: "60pt", padding: "20px" }} /> {renderResultCard(calcResult, scrollDown)}</div>
          }

        </QueueAnim>

      </OverPack>
      <div ref={ref} />
    </div>
  );
}

export default Feature1;
