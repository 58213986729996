/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import { Divider, Button } from 'antd';
import locale from "./_locale"

import Feature1 from './../Home/Feature1';
import Feature2 from './../Home/Feature2';
import Content3 from '../Home/Content3';
import EnergyCalculator from './EnergyCalculator';

import l from "../Home/_locale"
import {
    OmOssDatasource1,
    OmOssDatasource2,
    OmOssDatasource3,
    OmOssDatasource4,
    Content30DataSource
} from "./energy.source"

import "./less/energy.less"
import Contact from '../_general/contact';

const { location = {} } = typeof window !== 'undefined' ? window : {};
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

export default class Energy extends React.Component {
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef()
        this.state = {
            isMobile,
            show: !location.port
        };
    }

    scrollDown = () => {
        this.scrollRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    };

    componentDidMount() {
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        if (location.port) {
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 5);
        }
        document.title = "Dynamic - Energy"
    }
    render() {

        return (
            <div>
                <EnergyCalculator
                    id="EPCIFeature1_0"
                    key="EPCIFeature1_0"
                    isMobile={this.state.isMobile}
                    scrollDown={ () => this.scrollDown()}
                />,<Feature1
                    id="EPCIFeature1_0"
                    key="EPCIFeature1_0"
                    dataSource={OmOssDatasource1}
                    isMobile={this.state.isMobile}
                />  ,
                <Divider style={{ textAlign: "center", fontSize: "45px", marginTop: "100px" }}>{locale("energy_form_actions_title")}</Divider>,
                ,
                <div ref={this.scrollRef}><Content3
                    id="Content3_0"
                    key="Content3_0"
                    dataSource={Content30DataSource}
                    isMobile={this.state.isMobile}
                /></div>,
                <Feature2
                    id="ConsultingFeature2_0"
                    key="ConsultingFeature2_111"
                    dataSource={OmOssDatasource2}
                    isMobile={this.state.isMobile}
                />,
                {/* <p style={{
                    textAlign: "center",
                    position: "relative",
                    width: isMobile ? "90%" : "50%",
                    left: isMobile ? "5%" : "25%",
                    fontSize: "16px",
                    marginBottom: "40px"
                }}>
                    {locale("energy_form_save_more_description")}
                </p> */}
                ,
                <Feature1
                    id="EPCIFeature1_1"
                    key="EPCIFeature1_1"
                    dataSource={OmOssDatasource3}
                    isMobile={this.state.isMobile}
                />,
                <Feature2
                    id="ConsultingFeature2_0"
                    key="ConsultingFeature2_111"
                    dataSource={OmOssDatasource4}
                    isMobile={this.state.isMobile}
                />,
                <p style={{
                    textAlign: "center",
                    position: "relative",
                    width: isMobile ? "90%" : "50%",
                    left: isMobile ? "5%" : "25%",
                    fontSize: "16px",
                    marginBottom: "40px",
                    marginTop: "60px"
                }}>
                    {locale("energy_hook_part1")}
                </p>
                ,
                <p style={{
                    textAlign: "center",
                    position: "relative",
                    width: isMobile ? "90%" : "50%",
                    left: isMobile ? "5%" : "25%",
                    fontSize: "16px",
                    marginBottom: "40px",
                    fontWeight: "bold"
                }}>
                    {locale("energy_hook_part2")}
                </p>,
                <Contact />
            </div>
        )
    }
}
