/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Banner0 from './Banner0';
import Content1 from './Content1';
import Content3 from './Content3';
import Feature1 from './Feature1';
import Feature2 from './Feature2';
import Feature3 from './Feature3';
import Feature4 from './Feature4';
import Contact0 from './Contact0';
import Contact from "../_general/contact"

import {
  Banner00DataSource,
  Content10DataSource,
  Content30DataSource,
  Feature10DataSource,
  Feature30DataSource,
  Feature40DataSource,
  Contact00DataSource,
  EPCI1Datasource,
  Consulting2Datasource,
} from './data.source';
import './../antMotionStyle.less';
import { Divider } from 'antd';
import locale from "./_locale"
import { Referanser } from '../_general/referanser';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port,
    };
  }

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 5);
    }
  }

  render() {
    const children = [
      <Banner0
        id="Banner0_0"
        key="Banner0_0"
        dataSource={Banner00DataSource}
        isMobile={this.state.isMobile}
      // style={{
      //   boxShadow: "0px 0px 5px 5px blue"
      // }}
      />,
      <Content1
        id="Content1_0"
        key="Content1_0"
        dataSource={Content10DataSource}
        isMobile={this.state.isMobile}
      />,

      // Referanser
      <Referanser />,

      /* EPCI */
      <Divider style={{ textAlign: "center", fontSize: "45px", marginTop: "100px" }}>{locale("epci")}</Divider>,
      <p style={{
        textAlign: "center",
        position: "relative",
        width: isMobile ? "90%" : "50%",
        left: isMobile ? "5%" : "25%",
        fontSize: "16px",
        marginBottom: "40px"
      }}>{locale("epci_description")}</p>,
      // CIP
      <Feature1
        id="EPCIFeature1_0"
        key="EPCIFeature1_0"
        dataSource={EPCI1Datasource}
        isMobile={this.state.isMobile}
      />,

      /* Rådgiving/consulting */
      <Divider style={{ textAlign: "center", fontSize: "45px", marginTop: "100px" }}>{locale("consulting")}</Divider>,
      <p style={{
        textAlign: "center",
        position: "relative",
        width: isMobile ? "90%" : "50%",
        left: isMobile ? "5%" : "25%",
        fontSize: "16px",
        marginBottom: "40px"
      }}>
        {locale("consulting_description")}
      </p>,
      <Feature2
        id="ConsultingFeature2_0"
        key="ConsultingFeature2_0"
        dataSource={Consulting2Datasource}
        isMobile={this.state.isMobile}
      />,
      //   <Customers0
      //   id="ConsultingContent3_0"
      //   key="ConsultingContent3_0"
      //   dataSource={Consulting3DataSource}
      //   isMobile={this.state.isMobile}
      // />,

      /* IIOT og analyse */
      <Divider style={{ textAlign: "center", fontSize: "45px", marginTop: "100px" }}>{locale("IIOTheader")}</Divider>,
      <p style={{
        textAlign: "center",
        position: "relative",
        width: isMobile ? "90%" : "50%",
        left: isMobile ? "5%" : "25%",
        fontSize: "16px",
        marginBottom: "40px"
      }}>
        {locale("IIOT_description")}
      </p>,
      <Content3
        id="Content3_0"
        key="Content3_0"
        dataSource={Content30DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Content0
      //   id="Content0_0"
      //   key="Content0_0"
      //   dataSource={Content00DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Feature1
        id="Feature1_0"
        key="Feature1_0"
        dataSource={Feature10DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Feature2
      //   id="Feature2_0"
      //   key="Feature2_0"
      //   dataSource={Feature20DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Feature3
        id="Feature3_0"
        key="Feature3_0"
        dataSource={Feature30DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature4
        id="Feature4_0"
        key="Feature4_0"
        dataSource={Feature40DataSource}
        isMobile={this.state.isMobile}
      />,
      //   <Content2
      //   id="Content2_0"
      //   key="Content2_0"
      //   dataSource={Content20DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Contact />,
      <Contact0
        id="Contact0_0"
        key="Contact0_0"
        dataSource={Contact00DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {this.state.show && children}
      </div>
    );
  }
}
