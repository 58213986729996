import synnove_3 from "../static/synnove_3.PNG"
import bolbs from "../static/pexels-rodolfo-clix-1036936.jpg"
import powerGrid from "../static/pexels-pixabay-371936.jpg"
import solarPower from "../static/pexels-dimitri-c-7102661.jpg"
import dynamic_iiot_1 from "../static/dynamic_iiot_1.png"
import feedingsystem_2 from "../static/feedingsystem_2.png"
import locale from "../Home/_locale"
import consulting_1 from "../static/consulting_woodtech.jpg"

export const OmOssDatasource1 = {
  wrapper: { className: "home-page-wrapper feature1-wrapper" },
  OverPack: { className: "home-page feature1", playScale: 0.3 },
  imgWrapper: { className: "feature1-img", md: 10, xs: 24 },
  img: {
    children: powerGrid,
  },
  textWrapper: { className: "feature1-text", md: 14, xs: 24 },
  title: { className: "feature1-title", children: locale("energy_info_title") },
  content: {
    className: "feature1-content",
    children:
      locale("energy_info_description"),
  },
};
export const OmOssDatasource2 = {
  wrapper: { className: "home-page-wrapper feature2-wrapper" },
  OverPack: { className: "home-page feature2", playScale: 0.3 },
  imgWrapper: { className: "feature2-img", md: 10, xs: 24 },
  img: {
    children: solarPower,
  },
  textWrapper: { className: "feature2-text", md: 14, xs: 24,style: {marginTop: "30px"} },
  title: { className: "feature2-title", children: locale("energy_save_title") },
  content: {
    className: "feature2-content",
    children:
    locale("energy_save_description")
  },
};
export const OmOssDatasource3 = {
  wrapper: { className: "home-page-wrapper feature1-wrapper" },
  OverPack: { className: "home-page feature1", playScale: 0.3 },
  imgWrapper: { className: "feature1-img", md: 10, xs: 24 },
  img: {
    children: bolbs,
  },
  textWrapper: { className: "feature1-text", md: 14, xs: 24 },
  title: { className: "feature1-title", children: "" },
  content: {
    className: "feature1-content",
    children:
      locale("energy_save_more_description"),
  },
};

export const OmOssDatasource4 = {
  wrapper: { className: "home-page-wrapper feature1-wrapper" },
  OverPack: { className: "home-page feature1", playScale: 0.3 },
  imgWrapper: { className: "feature1-img", md: 10, xs: 24 },
  img: {
    children: consulting_1,
  },
  textWrapper: { className: "feature1-text", md: 14, xs: 24, style: {marginTop: "-50px"} },
  title: { className: "feature1-title", children: locale("energy_help_you_title") },
  content: {
    className: "feature1-content",
    children:
      locale("energy_help_you_description"),
  },
};

export const Content30DataSource = {
  wrapper: { className: "home-page-wrapper content3-wrapper" },
  page: { className: "home-page content3" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [{ name: "title", children: "Automatiser dine arbeidsoppgaver" }],
  },
  childWrapper: {
    className: "content3-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content3-block",
        md: 12,
        xs: 24,
        children: {
          className: "content3-block-item",
          children: [
            {
              name: "image",
              className: "content3-block-icon",
              children: "/innsikt.png",
            },
            {
              name: "title",
              className: "content3-block-title",
              children: locale("energy_actions_description_25p_title"),
            },
            {
              name: "content",
              children:
                locale("energy_actions_description_25p_description"),
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content3-block",
        md: 12,
        xs: 24,
        children: {
          className: "content3-block-item",
          children: [
            {
              name: "image",
              className: "content3-block-icon",
              children: "/increase_transparent.png",
            },
            {
              name: "title",
              className: "content3-block-title",
              children: locale("energy_actions_description_45p_title"),
            },
            {
              name: "content",
              children:
                locale("energy_actions_description_45p_description"),
            },
          ],
        },
      },
      // {
      //   name: "block2",
      //   className: "content3-block",
      //   md: 8,
      //   xs: 24,
      //   children: {
      //     className: "content3-block-item",
      //     children: [
      //       {
      //         name: "image",
      //         className: "content3-block-icon",
      //         children: "/timemoney_transparent.png",
      //       },
      //       {
      //         name: "title",
      //         className: "content3-block-title",
      //         children: locale("IIOTcard_3_header"),
      //       },
      //       {
      //         name: "content",
      //         children:
      //           locale("IIOTcard_3_body"),
      //       },
      //     ],
      //   },
      // },
    ],
  },
};
